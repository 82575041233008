*{  
  
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: "Poppins", sans-serif;
  
}

body {
  background: #000;
  color: #fff;
  padding: 2rem;
}

